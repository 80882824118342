import {
  Container,
  SimpleGrid,
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsPentagon } from "react-icons/bs";

import { GiEgyptianSphinx } from "react-icons/gi";
import { FaInfinity } from "react-icons/fa";

import { ReactElement } from "react";

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={12}
        h={12}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600} color="white">
        {text}
      </Text>
    </Stack>
  );
};

export default function Infomation() {
  return (
    <Box id="details" bg={"yellow.400"}>
      <Container maxW={"6xl"} py={16}>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={10}
          alignItems="center"
        >
          <Stack
            spacing={{ base: 12, md: 4 }}
            // divider={
            //   <StackDivider
            //     borderColor={useColorModeValue("gray.100", "gray.700")}
            //   />
            // }
          >
            <Feature
              icon={<Icon as={FaInfinity} color={"yellow.500"} w={10} h={10} />}
              iconBg={useColorModeValue("yellow.100", "yellow.900")}
              text={"Infinity"}
            />
            <Feature
              icon={
                <Icon as={GiEgyptianSphinx} color={"orange"} w={10} h={10} />
              }
              iconBg={useColorModeValue("green.100", "green.900")}
              text={"Eternity"}
            />
            <Feature
              icon={<Icon as={BsPentagon} color={"purple.500"} w={10} h={10} />}
              iconBg={useColorModeValue("purple.100", "purple.900")}
              text={"Perfection"}
            />
          </Stack>

          <Stack spacing={{ base: 12, md: 4 }}>
            <Heading color="white">What is Phi-Force?</Heading>
            <Text color={"orange.500"} fontSize={"lg"}>
              There is an infinite source of power within each one of us. If we
              properly tap into it we can manifest what we want in miraculous
              ways. Phi-force is a novel concept/principle that emerged from my
              investigation of various esoteric and new-age phenomena.
            </Text>
            <Text color={"green.400"} fontSize={"lg"}>
              My work is mainly inspired by the writings and the work of
              Aleister Crowley, Ramsey Dukes, Peter J. Carroll, Hagen Von
              Tulien, Michael Kelly, Austin Osman Spare, Kenneth Grant, Jack
              Parsons and others.
            </Text>
            <Text color={"orange.400"} fontSize={"lg"}>
              Beliefs around the Law Of Attraction are neither true or false so
              either a serious or a non-serious act of faith is required in
              order to proceed. Use caution and handle with care or not.
            </Text>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
