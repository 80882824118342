import { ReactNode } from "react";
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Image,
  Text,
  Link,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";

import { URL_LOGO } from "../../constants";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const Footer = () => {
  return (
    <Box>
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
          <Stack align={"flex-start"}>
            <ListHeader>Links</ListHeader>
            <Link href={"mailto:ludwigvondestin@gmail.com"}>Contact Me</Link>
          </Stack>

          <Stack align={"flex-start"}>
            <ListHeader>Your Reality Bending Guide</ListHeader>
            <ListHeader>Use Transcendental Metaphysics</ListHeader>
            <ListHeader>To Manifest Your Destiny</ListHeader>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box py={10}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Image
            boxSize="50px"
            objectFit="cover"
            borderRadius="full"
            src={URL_LOGO}
            alt=""
          />
        </Flex>
        <Text pt={6} fontSize={"sm"} textAlign={"center"}>
          © 2023 Phi Force Academy. All rights reserved.
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
